if ("serviceWorker" in navigator) {
	navigator.serviceWorker.register("/worker.js").then(registration => {
		if (registration.installing) {
			console.log("Service worker installing");
		} else if (registration.waiting) {
			console.log("Service worker installed");
		} else if (registration.active) {
			console.log("Service worker active");
		}
	}).catch(err => {
		console.error(`Service worker registration failed with ${err}`);
	});

	const offlineMessage = document.querySelector('#offline-message');
	window.addEventListener('offline', () => {
		offlineMessage.classList.remove('hidden');
		offlineMessage.setAttribute('aria-hidden', 'false');
	});

	window.addEventListener('online', () => {
		offlineMessage.classList.add('hidden');
		offlineMessage.setAttribute('aria-hidden', 'true');
	});
}
